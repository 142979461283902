<template>
  <v-main>
    <v-sheet
      :color="$vuetify.breakpoint.mobile ? 'white' : '#fafbfc'"
      class="fill-height"
    >
      <v-container fluid class="fill-height">
        <v-row class="mb-6" justify="center" no-gutters>
          <v-col md="auto" class="col-sm-12">
            <!-- Login -->
            <v-card
              v-if="!showForgotPassword"
              :loading="loading && !$vuetify.breakpoint.mobile"
              :class="`
                d-flex
                text-center
								${$vuetify.breakpoint.mobile ? 'elevation-0' : 'shadow-md pa-8'}
                flex-column
                ma-auto
                rounded-sm
								pt-0`"
              :width="$vuetify.breakpoint.mobile ? '100%' : 400"
              min-height="300"
            >
              <h2 class="grey--text text--darken-2 mt-4">
                AEoI/CRS Reporting Tool
              </h2>
              <div class="d-flex flex-column py-6">
                <span class="subtitle grey--text text--darken-2"
                  >Login into your account</span
                >
                <p v-if="this.error" class="mb-0 pb-0 mt-4 red--text">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon>
                  {{ this.error }}
                </p>
              </div>
              <v-alert dense type="info" v-if="showAlert"
                >Your session has expired. Please enter your credentials to
                continue.</v-alert
              >

              <div class="d-flex flex-column text-left">
                <v-form ref="signInForm" @submit.prevent="loginHandler()">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    type="email"
                    dense
                    outlined
                    background-color="#e8f0fe"
                    autofocus
                  ></v-text-field>

                  <v-text-field
                    v-model="password"
                    label="Password"
                    background-color="#e8f0fe"
                    dense
                    outlined
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="
                      showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                    "
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                  <v-btn
                    color="primary text-capitalize"
                    block
                    type="submit"
                    class="shadow-sm"
                    :loading="loading"
                    :disabled="loading"
                    >Sign In</v-btn
                  >
                </v-form>
              </div>

              <div class="text-center">
                <ul
                  class="d-flex justify-center pa-0 pt-3"
                  style="list-style: none"
                >
                  <li class="primary--text">
                    <v-btn
                      class="text-capitalize"
                      depressed
                      text
                      color="primary"
                      link
                      @click="toggleForgotPassword"
                      >Can't sign in?</v-btn
                    >
                  </li>
                </ul>
              </div>
            </v-card>

            <!-- Request Password Change -->
            <v-card
              v-else
              :loading="loading && !$vuetify.breakpoint.mobile"
              :class="`
                d-flex
                text-center
								${$vuetify.breakpoint.mobile ? 'elevation-0' : 'shadow-md pa-6'}
                flex-column
                ma-auto
                rounded-sm
								pt-6`"
              :width="$vuetify.breakpoint.mobile ? '100%' : 600"
              :flat="true"
              min-height="300"
            >
              <img
                class="d-block mb-6 mt-6"
                src="../../assets/img/security.svg"
                :style="`height: 100px;  margin: 0 auto`"
                alt=""
              />
              <p class="headline mb-2">Request Password Change?</p>
              <span class="subtitle grey--text text--darken-2 mb-8"
                >Enter your email address. A link will be sent to your email
                account to reset your password.</span
              >

              <div class="d-flex flex-column text-left">
                <ValidationObserver ref="requestObserver">
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    events="['blur']"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="email"
                      label="Email"
                      type="email"
                      dense
                      :error-messages="errors"
                      outlined
                      background-color="#e8f0fe"
                      autofocus
                    ></v-text-field>
                  </ValidationProvider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-2 text-capitalize"
                      dark
                      outlined
                      @click="toggleForgotPassword"
                      class="shadow-sm"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="primary text-capitalize"
                      class="shadow-sm"
                      @click="onRequestPasswordChange"
                      :loading="loading"
                      :disabled="loading"
                      >Send Request</v-btn
                    >
                  </v-card-actions>
                </ValidationObserver>
              </div>
            </v-card>
          </v-col>

          <!-- Footer -->
          <v-col class="col-12 text-center" align-self="center">
            <v-divider class="my-8 d-block" style="width: 40%; margin: 0 auto">
            </v-divider>
            <div class="d-block" style="width: 300px; margin: 0 auto">
              <img
                class="d-block mb-8 mt-8"
                style="height: 36px; margin: 0 auto"
                src="../../assets/logos/company.png"
                alt=""
              />
              <span class="text-mute">
                Developed by
                <a
                  class="primary--text text-decoration-none"
                  target="__BLANK"
                  href="https://technosoftja.com"
                  >A&S Technosoft Solutions</a
                >
              </span>
              <ul
                class="d-flex justify-center pa-0 pt-3"
                style="list-style: none"
              >
                <li class="primary--text">Terms</li>
                <span class="mx-2">•</span>
                <li class="primary--text">Privacy Policy</li>
                <span class="mx-2">•</span>
                <li class="primary--text">
                  <a
                    href="https://www.oecd.org/tax/exchange-of-tax-information/common-reporting-standard-status-message-xml-schema-user-guide-for-tax-administrations.pdf"
                    >CRS Schema</a
                  >
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-main>
</template>

<script>
import Logger from '../../lib/logger';
import { mapGetters, mapActions } from 'vuex';
import CoreMixin from '@/mixins/core.mixin';

export default {
  name: 'LoginPage',
  mixins: [CoreMixin],
  data: () => ({
    showAlert: false,
    loading: false,
    showPassword: false,
    email: '',
    password: '',
    showForgotPassword: false
  }),

  computed: {
    ...mapGetters('authModule', ['status', 'error'])
  },
  async beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (to.query.token) {
        try {
          await vm.verifyPasswordResetToken(to.query.token);
          vm.showSnack({
            color: 'info',
            title: `Request Sent`,
            message: `Please check your email for your new password.`
          });
        } catch (e) {
          console.log('[error]: ignore - ', e);
        }
      }
      next('/auth/login');
    });
  },

  async mounted() {
    const { session } = this.$route.query;

    if (session?.toString() === 'false') {
      this.showAlert = true;
    }

    await this.clearErrors();
  },

  methods: {
    ...mapActions('authModule/', [
      'signIn',
      'requestPasswordChange',
      'verifyPasswordResetToken',
      'clearErrors'
    ]),

    toggleForgotPassword() {
      this.showForgotPassword = !this.showForgotPassword;
    },

    async loginHandler() {
      this.loading = true;
      this.showAlert = false;

      try {
        await this.signIn({
          email: this.email,
          password: this.password
        });
        if (this.status === 'failed') {
          return;
        }

        if (this.user.shouldChangePassword) {
          this.$router.push('/app/user-account');
          return;
        }
        this.$router.push('/app/reports');
      } catch (e) {
        Logger.error('authentication: ', e);
      } finally {
        this.loading = false;
      }
    },

    async onRequestPasswordChange() {
      const isValid = await this.$refs.requestObserver.validate();
      if (!isValid) return;

      let noError = true;
      this.showAlert = false;

      try {
        this.loading = true;
        await this.requestPasswordChange(this.email);

        await this.clearErrors();
      } catch (e) {
        if (e.response && e.response.status === 404) return;
        noError = false;

        this.httpErrorHandler(e);
      } finally {
        if (noError) {
          this.showSnack({
            color: 'info',
            title: `Request Sent`,
            message: `If the email you entered exist then a reset link has been sent to it`
          });
          this.toggleForgotPassword();
        }

        this.loading = false;
      }
    }
  }
};
</script>